<template>
  <v-card light>
    <v-container fluid>
      <v-row dense>
        <v-col>
          <v-card color="grey lighten-4">
            <v-container fluid>
              <v-row dense justify="end">
                <v-col cols="6">
                  <h3>Notes:</h3>
                </v-col>
                <v-col class="text-right" cols="6" >
                  <v-btn text small icon color="color3" @click="refresh" :loading="loading" class="ma-0">
                    <v-icon style="font-size: small">fas fa-sync-alt</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-list three-line>
                    <v-list-item
                      v-for="(item, i) in _notes"
                      :key="i"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle style="font-size: x-small">{{item.by}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="black--text">{{item.text}}</v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: x-small">{{item.dtCreated | unixToLocalDt}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ['notes', 'loading'],
  data () {
    return {
      desc: true
    }
  },
  computed: {
    _notes () {
      const n = this.notes
      return !this.desc || !n || n.length === 0 ? n : n.sort((a, b) => {
        return new Date(b.dtCreated) - new Date(a.dtCreated)
      })
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh-click')
    }
  }
}
</script>

<style>

</style>
